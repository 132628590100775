import Api from '../api';
import Constants from "../constants";
export default {
    namespaced: true,
    state: {
        requirementsPosition: 1,
        collateralRequirements: null,
        personalRequirements: null,
        loanRequirements: null,
        collateral: [],
        institutions: [],
        products: [],
        fees: [],
        collateralFees: [],
        locations: [],
        counties: []
    },
    mutations: {
        SET_POSITION(state, position) {
            state.requirementsPosition = position;
        },
        SET_COLLATERAL(state, requirements) {
            state.collateralRequirements = requirements;
        },
        SET_PERSONAL(state, requirements) {
            state.personalRequirements = requirements;
        },
        SET_LOAN(state, requirements) {
            state.loanRequirements = requirements;
        },
        SET_COLLATERAL_LIST(state, collateral) {
            state.collateral = collateral;
        },
        SET_INSTITUTION_LIST(state, institutions) {
            state.institutions = institutions;
        },
        SET_PRODUCTS(state, products) {
            state.products = products;
        },
        SET_LOAN_FEES(state, fees) {
            state.fees = fees;
        },
        SET_COLLATERAL_FEES(state, fees) {
            state.collateralFees = fees;
        },
        SET_LOCATIONS(state, locations) {
            state.locations = locations;
        },
        SET_COUNTIES(state, counties) {
            state.counties = counties;
        }
    },
    getters: {
        GET_POSITION(state, getters) {
            return state.requirementsPosition;
        },
        GET_COLLATERAL(state, getters) {
            return state.collateralRequirements;
        },
        GET_PERSONAL(state, getters) {
            return state.personalRequirements;
        },
        GET_LOAN(state, getters) {
            return state.loanRequirements;
        },
        GET_LOCATIONS(state, getters) {
            return state.locations;
        },
        GET_COUNTIES(state, getters) {
            return state.counties;
        }
    },
    actions: {
        SET_POSITION(context, position) {
            context.commit(Constants.SET_POSITION, position);
        },
        SET_LOAN(context, payload) {
            context.commit(Constants.SET_LOAN, payload.requirements);
            if (payload.position)
                context.dispatch(Constants.SET_POSITION, 2);
        },
        SET_COLLATERAL(context, requirements) {
            context.commit(Constants.SET_COLLATERAL, requirements);
            //context.dispatch(Constants.SET_POSITION, 3);
        },
        SET_PERSONAL(context, requirements) {
            context.commit(Constants.SET_PERSONAL, requirements);
            //context.dispatch(Constants.SET_POSITION, 4);
        },
        SET_COLLATERAL_LIST(context) {
            Api.collateral().then((response) => {
                context.commit(Constants.SET_COLLATERAL_LIST, response.data.data);
            });
        },
        SET_LOAN_FEES(context) {
            Api.loanFees().then((response) => {
                context.commit(Constants.SET_LOAN_FEES, response.data.data);
            });
        },
        SET_COLLATERAL_FEES(context, payload) {
            Api.collateralFees(payload).then((response) => {
                context.commit(Constants.SET_COLLATERAL_FEES, response.data.data);
            });
        },
        SET_INSTITUTION_LIST(context) {
            Api.institutions().then((response) => {
                context.commit(Constants.SET_INSTITUTION_LIST, response.data.data);
            });
        },
        SET_LOCATIONS(context) {
            Api.locations().then((response) => {
                context.commit(Constants.SET_LOCATIONS, response.data.data);
            });
        },
        SET_COUNTIES(context) {
            Api.counties().then((response) => {
                context.commit(Constants.SET_COUNTIES, response.data.data);
            });
        },
        FILTER_PRODUCTS(context, params) {
            return Api.filter(params).then((response) => {
                context.commit(Constants.SET_PRODUCTS, response.data.data);
            });
        }
    },
    modules: {
    }
};
