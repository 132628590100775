<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container px-0">
                <div class="row">
                    <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
                        <div class="text-center p-3 mb-4 border-bottom">
                            <h4 class="text-muted"><span>Choice of display</span></h4>
                        </div>
                        <div class="form-group">
                          <label>
                            Which of the following two display options do you want to see first? You can still also see the other display option afterwards.
                          </label>
                          <div class="list-group" :class="{ 'is-invalid': v$.display_type.$errors.length }">
                            <div class="list-group-item" v-for="(option, index) in display_options">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" name="display_type" :id="`display_type_${option.id}`" :value="`display_type_${option.id }`" v-model="display_type">
                                <label class="custom-control-label" :for="`display_type_${option.id}`">
                                  {{ option.label }}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="invalid-feedback" v-for="error of v$.display_type.$errors" :key="error.$uid">
                            {{ error.$message }}
                          </div>
                        </div>
                        <div class="form-group">
                          <label>
                            Are you looking for a group loan or individual loan?
                          </label>
                          <div class="list-group">
                            <div class="list-group-item">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" name="loan_type" id="individual"
                                       value="individual" v-model="loan_type">
                                <label class="custom-control-label" for="individual">
                                  Individual Loan
                                </label>
                              </div>
                            </div>
                            <div class="list-group-item">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" name="loan_type" id="group"
                                       value="group" v-model="loan_type">
                                <label class="custom-control-label" for="group">
                                  Group Loan
                                </label>
                              </div>
                            </div>
                            <div class="list-group-item">
                              <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" name="loan_type" id="either"
                                       value="either" checked v-model="loan_type">
                                <label class="custom-control-label" for="either">
                                  Either type
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="text-center" v-if="processing">
              <p class="p-0 m-0">Processing... Please wait!</p>
            </div>
            <div class="d-flex" v-else>
                <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
                  <i class="mdi mdi-arrow-left"></i> Previous
                </a>
                <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
                    Next <i class="mdi mdi-arrow-right"></i>
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {numeric, required, minValue} from '@vuelidate/validators';
    import {createNamespacedHelpers} from 'vuex'

    const {mapActions, mapGetters} = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import Constants from '../constants';
    import Api from '../api';
    import Firebase from "../firebase";
    import moment from 'moment';
    import Locations from "./Locations";
    import _ from "lodash";

    export default {
        name: "DisplayOptions",
        components: {Locations, NavTabs, LoanCard},
        data() {
            return {
                time: {start: null, end: null},
                display_type: '',
                loan_type: 'either',
                processing: false,
                display_options: [
                  {
                    id: 1,
                    label: 'For the desired loan amount, repayment frequency and loan duration, show the total cost of the loan, instalment amount and other characteristics across loan products',
                  },
                  {
                    id: 2,
                    label: 'For the indicated maximally affordable instalment amount and the desired repayment frequency and loan duration, show the approximately maximum loan amount, total cost and other characteristics across products',
                  },
                ]
            }
        },
        setup() {
            return {v$: useVuelidate()}
        },
        mounted() {
            this.time.start = moment().format();

            //Firebase.sendEvent
            if (this.requirements)
                this.populate(this.requirements);

            this.shuffleArray(this.display_options);
        },
        validations() {
            return {
              display_type: {required},
              loan_type: {required},
            }
        },
        computed: {
            ...mapGetters({
              requirements: Constants.GET_LOAN,
              personal: Constants.GET_PERSONAL,
              collateral: Constants.GET_COLLATERAL,
            }),
            loan() {
                return {
                    house_location: this.house_location,
                    business_location: this.business_location,
                    time: this.time,
                    respondent_id: this.respondent_id,
                    loan_size: this.loan_size,
                    payment_frequency: this.payment_frequency,
                    loan_term: this.loan_term,
                    card: this.card,
	                  loan_type: this.loan_type,
                    display_type: this.display_type,
                    max_installment: this.max_installment,
                }
            },
            loan_term_units() {
                return this.payment_frequency === 'weekly' ? 'Weeks' : 'Months';
            }
        },
        methods: {
            ...mapActions([Constants.SET_LOAN, Constants.SET_POSITION, Constants.FILTER_PRODUCTS]),
            previous () {
              //Firebase.sendEvent
              this.SET_POSITION(2);
            },
            next() {
                this.v$.$touch();
                if (this.v$.$error) return;

                this.time.end = moment().format();

                //Firebase.sendEvent

                if (!this.processing) {
                  this.processing = true;
                  this.FILTER_PRODUCTS({personal: this.personal, loan: this.loan, collateral: this.collateral})
                      .then(() => {
                        this.processing = false;
                        //Firebase.sendEvent("Personal requirements submitted");
                        this.SET_LOAN({requirements: this.loan, position: false});
                        this.SET_POSITION(4);
                      })
                      .catch((error) => {
                        this.processing = false;
                        console.log(error);
                        console.log(JSON.stringify(error));
                      });
                }
            },
            populate(requirements) {
                this.respondent_id = requirements.respondent_id;
                this.loan_size = requirements.loan_size;
                this.payment_frequency = requirements.payment_frequency;
                this.loan_term = requirements.loan_term;
                this.card = requirements.card;
                this.loan_type = requirements.loan_type;
                this.display_type = requirements.display_type;
                this.max_installment = requirements.max_installment;
            },
            shuffleArray(array) {
              for (let i = array.length - 1; i >= 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
              }
            }
        }
    }
</script>

<style scoped>

</style>