<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container px-0">
                <div class="row">
                    <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
                        <div class="text-center p-3 mb-4 border-bottom">
                            <h4 class="text-muted"><span>Preferences</span></h4>
                        </div>
                        <div class="form-group">
                          <label for="loan-size">Maximally affordable instalment per <strong>{{ payment_frequency === 'weekly' ? 'week' : payment_frequency === 'monthly' ? 'month' : '[frequency]'}}</strong></label>
                          <div class="input-group mb-3">
                            <VueNumberFormat id="max-installment" v-model:value="max_installment" class="form-control"
                                             :class="{ 'is-invalid': v$.max_installment.$errors.length }"></VueNumberFormat>
                            <div class="input-group-append">
                              <span class="input-group-text">UGX</span>
                            </div>
                            <div class="invalid-feedback" v-for="error of v$.max_installment.$errors" :key="error.$uid">
                              {{ error.$message }}
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="loan-size">Loan Size</label>
                            <div class="input-group mb-3">
                                <VueNumberFormat id="loan-size" v-model:value="loan_size" class="form-control"
                                                 :class="{ 'is-invalid': v$.loan_size.$errors.length }"></VueNumberFormat>
                                <div class="input-group-append">
                                    <span class="input-group-text">UGX</span>
                                </div>
                                <div class="invalid-feedback" v-for="error of v$.loan_size.$errors" :key="error.$uid">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="payment-frequency" class="h6">Repayment frequency</label>
                            <select class="form-control custom-select" id="payment-frequency"
                                    v-model="payment_frequency">
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="loan-term">Loan Term</label>
                            <div class="input-group mb-3">
                                <VueNumberFormat id="loan-term" v-model:value="loan_term" class="form-control"
                                                 :class="{ 'is-invalid': v$.loan_term.$errors.length }"></VueNumberFormat>
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ loan_term_units }}</span>
                                </div>
                                <div class="invalid-feedback" v-for="error of v$.loan_term.$errors" :key="error.$uid">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="d-flex">
                <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
                  <i class="mdi mdi-arrow-left"></i> Previous
                </a>
                <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
                    Next <i class="mdi mdi-arrow-right"></i>
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {numeric, required, minValue} from '@vuelidate/validators';
    import {createNamespacedHelpers} from 'vuex'

    const {mapActions, mapGetters} = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import Constants from '../constants';
    import Api from '../api';
    import Firebase from "../firebase";
    import moment from 'moment';
    import Locations from "./Locations";

    export default {
        name: "Preferences",
        components: {Locations, NavTabs, LoanCard},
        data() {
            return {
                time: {start: null, end: null},
                respondent_id: '',
                loan_size: '50000',
                payment_frequency: 'monthly',
                loan_term: '',
                card: '0',
                house_location: 'current_location',
                location_of_house: '',
                business_location: 'current_location',
                location_of_business: '',
                loan_type: 'either',
                max_installment: '',
            }
        },
        setup() {
            return {v$: useVuelidate()}
        },
        mounted() {
            this.time.start = moment().format();

            Firebase.sendEvent("Loan requirements tab");
            if (this.requirements)
                this.populate(this.requirements);

            this.getLocation();
        },
        validations() {
            return {
                //respondent_id: {required},
                //house_location: {required},
                //business_location: {required},
                loan_size: {
                    required, numeric, minValue: minValue(50000)
                },
                loan_term: {
                    required, numeric
                },
                max_installment: {required}
            }
        },
        computed: {
            ...mapGetters({requirements: Constants.GET_LOAN}),
            loan() {
                return {
                    house_location: this.house_location,
                    business_location: this.business_location,
                    time: this.time,
                    respondent_id: this.respondent_id,
                    loan_size: this.loan_size,
                    payment_frequency: this.payment_frequency,
                    loan_term: this.loan_term,
                    card: this.card,
	                  loan_type: this.loan_type,
                    max_installment: this.max_installment
                }
            },
            loan_term_units() {
                return this.payment_frequency === 'weekly' ? 'Weeks' : 'Months';
            }
        },
        methods: {
            ...mapActions([Constants.SET_LOAN, Constants.SET_POSITION]),
            previous () {
              //Firebase.sendEvent
              this.SET_POSITION(1);
            },
            next() {
                this.v$.$touch();
                if (this.v$.$error) return;

                this.time.end = moment().format();

                Firebase.sendEvent("Loan requirements submitted");

                this.SET_LOAN({requirements: this.loan, position: false});
                this.SET_POSITION(3);
            },
            populate(requirements) {
                this.respondent_id = requirements.respondent_id;
                this.loan_size = requirements.loan_size;
                this.payment_frequency = requirements.payment_frequency;
                this.loan_term = requirements.loan_term;
                this.card = requirements.card;
                this.loan_type = requirements.loan_type;
                this.max_installment = requirements.max_installment;
            },
            getLocation() {
                console.log("getLocation");
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        Api.update({
                            location: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        }).then(() => {
                            Firebase.sendEvent("Location access permissions granted");
                            console.log("Success");
                        }).catch(() => {
                            console.log("error");
                        });
                    })
                } else {
                    alert("Geolocation is not supported by this browser!");
                }
            },
            setVillage(payload) {
                if (payload.position === 'house')
                    this.house_location = payload.village;

                if (payload.position === 'business')
                    this.business_location = payload.village;
            }
        },
        watch: {
            location_of_house() {
                if (this.location_of_house === 'current_location') {
                    this.house_location = this.location_of_house;
                } else {
                    this.house_location = '';
                }
            },
            location_of_business() {
                if (_.includes(['current_location', 'house_location'], this.location_of_business)) {
                    this.business_location = this.location_of_business;
                } else {
                    this.business_location = '';
                }
            }
        }
    }
</script>

<style scoped>

</style>