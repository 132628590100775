<template>
  <loan-card>
    <template v-slot:header>
      <nav-tabs></nav-tabs>
    </template>
    <template v-slot:body>
      <div class="container px-0">
        <div class="row">
          <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
            <div class="text-center p-3 mb-4 border-bottom">
              <h4 class="text-muted"><span>Borrower inputs</span></h4>
            </div>
            <div class="list-group mb-3" :class="{ 'border border-danger': v$.house_location.$errors.length }">
              <div class="list-group-item text-dark" id="house-location-label">
                Location of house
              </div>
              <div class="list-group-item text-dark">
                <div class="form-check">
                  <input class="form-check-input" checked v-model="location_of_house" type="radio" value="current_location" id="house-location-current">
                  <label class="form-check-label" for="house-location-current">
                    Current location
                  </label>
                </div>
              </div>
              <div class="list-group-item text-dark">
                <div class="form-check">
                  <input class="form-check-input" v-model="location_of_house" type="radio" value="different_location" id="house-location-different">
                  <label class="form-check-label" for="house-location-different">
                    Different location
                  </label>
                </div>
              </div>
              <div class="list-group-item p-0" id="house-village`" v-if="location_of_house === 'different_location'">
                <locations v-on:village="setVillageLocation" position="house"></locations>
                <input type="hidden" v-model="house_location"/>
              </div>
              <div class="list-group-item text-danger" v-for="error of v$.house_location.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

            <div class="list-group mb-3" :class="{ 'border border-danger': v$.business_location.$errors.length }">
              <div class="list-group-item text-dark" id="business-location-label">
                Location of business
              </div>
              <div class="list-group-item text-dark">
                <div class="form-check">
                  <input class="form-check-input" checked v-model="location_of_business" type="radio" value="current_location" id="business-location-current">
                  <label class="form-check-label" for="business-location-current">
                    Current location
                  </label>
                </div>
              </div>
              <div class="list-group-item text-dark">
                <div class="form-check">
                  <input class="form-check-input" v-model="location_of_business" type="radio" value="house_location" id="business-location-house">
                  <label class="form-check-label" for="business-location-house">
                    Same as location of house
                  </label>
                </div>
              </div>
              <div class="list-group-item text-dark">
                <div class="form-check">
                  <input class="form-check-input" v-model="location_of_business" type="radio" value="different_location" id="business-location-different">
                  <label class="form-check-label" for="business-location-different">
                    Different location
                  </label>
                </div>
              </div>
              <div class="list-group-item p-0" id="business-village`" v-if="location_of_business === 'different_location'">
                <locations v-on:village="setVillageLocation" position="business"></locations>
                <input type="hidden" v-model="business_location"/>
              </div>
              <div class="list-group-item text-danger" v-for="error of v$.business_location.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

            <div class="form-group">
              <label for="collateral-type" class="h6">Collateral Type</label>
              <div class="list-group" id="collateral-type">
                <div class="list-group-item" v-for="(cz, i) in collateral">
                  <div class="form-check">
                    <input class="form-check-input" name="collateral_type" v-model="collateral_type" type="checkbox" :value="cz.id" :id="`check-${i}-box`">
                    <label class="form-check-label" :for="`check-${i}-box`">
                      {{ cz.collateral_name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="business-profit">Business Profit</label>
                    <div class="input-group mb-3">
                      <VueNumberFormat id="business-profit" v-model:value="business_profit" class="form-control"
                                       :class="{ 'is-invalid': v$.business_profit.$errors.length }"></VueNumberFormat>
                      <div class="input-group-append">
                        <span class="input-group-text">UGX</span>
                      </div>
                      <div class="invalid-feedback" v-for="error of v$.business_profit.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="profit-frequency">Profit Frequency</label>
                    <select class="form-control custom-select" id="profit-frequency" v-model="profit_frequency"
                            :class="{ 'is-invalid': v$.profit_frequency.$errors.length }">
                      <option>Weekly</option>
                      <option>Monthly</option>
                    </select>
                    <div class="invalid-feedback" v-for="error of v$.profit_frequency.$errors" :key="error.$uid">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="hasCollateral">
              <label for="collateral-values">Collateral Value & Location</label>
              <div class="list-group mb-3 border-danger"
                   id="collateral-values" v-for="(v, p) in final_collateral_value" :key="v.id">
                <div class="list-group-item text-dark">
                  Value of {{ v.name }}
                </div>
                <div class="list-group-item p-1">
                  <div class="input-group m-0">
                    <VueNumberFormat :id="`${v.id}-collateral-value`"
                                     v-model:value="collateral_value[v.id].collateral_value" class="form-control"></VueNumberFormat>
                    <div class="input-group-append">
                      <span class="input-group-text">UGX</span>
                    </div>
                    <div class="invalid-feedback" :key="`${v.id}-error`">
                      Collateral value is required
                    </div>
                  </div>
                </div>
                <template v-if="collateral_value[v.id].location_required">
                  <div class="list-group-item text-dark">
                    Where is your collateral located?
                  </div>
                  <div class="list-group-item text-dark">
                    <div class="form-check">
                      <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="current_location" :id="`check-${v.id}-current`">
                      <label class="form-check-label" :for="`check-${i}-current`">
                        Current location
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item text-dark">
                    <div class="form-check">
                      <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="house_location" :id="`check-${v.id}-house`">
                      <label class="form-check-label" :for="`check-${i}-house`">
                        Same as location of house
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item text-dark">
                    <div class="form-check">
                      <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="business_location" :id="`check-${v.id}-business`">
                      <label class="form-check-label" :for="`check-${i}-business`">
                        Same as location of business
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item text-dark">
                    <div class="form-check">
                      <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="different_location" :id="`check-${v.id}-different`">
                      <label class="form-check-label" :for="`check-${i}-different`">
                        Different location
                      </label>
                    </div>
                  </div>
                  <template v-if="collateral_value[v.id].collateral_location === 'different_location'">
                    <div class="list-group-item p-0" :id="`${v.id}-collateral-village`">
                      <locations v-on:village="setVillage" :position="v.id"></locations>
                      <input type="hidden" v-model="collateral_value[v.id].collateral_village"/>
                    </div>
                    <div class="list-group-item text-danger border-danger d-none" :id="`${v.id}-collateral-village-text`">
                      Location of {{ v.name }} is required
                    </div>
                  </template>
                </template>
              </div>
            </div>

            <div class="form-group">
              <label>
                Gender
              </label>
              <div class="list-group">
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="gender" id="male" value="male" checked v-model="gender">
                    <label class="custom-control-label" for="male">
                      Male
                    </label>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="gender" id="female" value="female" v-model="gender">
                    <label class="custom-control-label" for="female">
                      Female
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                Do you have a Tax Identification Number (TIN)
              </label>
              <div class="list-group">
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="tin" id="tin-yes" value="1" checked v-model="tin">
                    <label class="custom-control-label" for="tin-yes">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="tin" id="tin-no" value="0" v-model="tin">
                    <label class="custom-control-label" for="tin-no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                Do you have a currently valid Trading License
              </label>
              <div class="list-group">
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="license" id="license-yes" value="1" checked v-model="license">
                    <label class="custom-control-label" for="license-yes">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="license" id="license-no" value="0" v-model="license">
                    <label class="custom-control-label" for="license-no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                Do you already have a bank account
              </label>
              <div class="list-group">
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="account" id="account-yes" value="1" v-model="account">
                    <label class="custom-control-label" for="account-yes">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="account" id="account-no" value="0" checked v-model="account">
                    <label class="custom-control-label" for="account-no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="account === '1'">
              <div class="form-group">
                <label for="account-institutions">At what Institutions</label>
                <select id="account-institutions" class="form-control custom-select" size="3" v-model="institution" multiple :class="{ 'is-invalid': institutionRequired }">
                  <option :value="i.id" v-for="(i, p) in institutions" :key="p">{{ i.name }}</option>
                </select>
                <div class="invalid-feedback">
                  Institutions are required
                </div>
              </div>
              <div class="form-group">
                <label>
                  Do you have bank statements
                </label>
                <div class="list-group">
                  <div class="list-group-item">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="statements" id="statements-yes" value="1" v-model="statements">
                      <label class="custom-control-label" for="statements-yes">
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="statements" id="statements-no" value="0" checked v-model="statements">
                      <label class="custom-control-label" for="statements-no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="statements === '1'">
                <label for="months">Number of Months Statements held for</label>
                <div class="input-group mb-3">
                  <VueNumberFormat id="months" v-model:value="statements_months" class="form-control"
                                   :class="{ 'is-invalid': statementsMonthsRequired }"></VueNumberFormat>
                  <div class="input-group-append">
                    <span class="input-group-text">Months</span>
                  </div>
                  <div class="invalid-feedback">
                    Number of Months Statements held for is required
                  </div>
                </div>
              </div>
            </template>
            <div class="form-group">
              <label>
                Do you have Guarantors
              </label>
              <div class="list-group">
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="guarantors" id="guarantors-yes" value="1" v-model="guarantors">
                    <label class="custom-control-label" for="guarantors-yes">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" name="guarantors" id="guarantors-no" value="0" checked v-model="guarantors">
                    <label class="custom-control-label" for="guarantors-no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="guarantors === '1'">
              <div class="form-group">
                <label for="guarantors-number">Number of Guarantors</label>
                <VueNumberFormat id="guarantors-number" v-model:value="guarantor_number" class="form-control"
                                 :class="{ 'is-invalid': guarantorNumberRequired }"></VueNumberFormat>
                <div class="invalid-feedback">
                  Number of Guarantors is required
                </div>
              </div>
              <div class="form-group">
                <label>
                  Is any of the Guarantors a client at a financial institution
                </label>
                <div class="list-group">
                  <div class="list-group-item">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="client" id="client-yes" value="1" v-model="client">
                      <label class="custom-control-label" for="client-yes">
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="client" id="client-no" value="0" v-model="client">
                      <label class="custom-control-label" for="client-no">
                        No, none of them is client at any institution
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="client" id="client-donot-know" value="do not know" v-model="client">
                      <label class="custom-control-label" for="client-donot-know">
                        Don’t know if they are client at an institution
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="client === '1'">
                <div class="form-group">
                  <label for="guarantors-number">Number of Guarantors that are clients at a financial institution</label>
                  <VueNumberFormat id="guarantors-number-clients" v-model:value="guarantor_number_clients" class="form-control"
                                   :class="{ 'is-invalid': guarantorNumberClientRequired }"></VueNumberFormat>
                  <div class="invalid-feedback">
                    Number of Guarantors is required
                  </div>
                </div>
                <div class="form-group" v-if="guarantor_number_clients > 0">
                  <label for="guarantors-institutions">Guarantor - Institution Details</label>
                  <div id="guarantors-institutions">
                    <div class="input-group mb-1" v-for="(r, i) in guarantor_range" :key="`guarantor-${i}-institutions-${rand}`">
                      <div class="input-group-prepend">
                        <label class="input-group-text" :for="`guarantor-${i}-institutions`">Guarantor {{ r + 1 }}</label>
                      </div>
                      <select class="form-control custom-select" :id="`guarantor-${i}-institutions`"
                              v-model="guarantor_institutions[r + 1].institutions" multiple size="3"
                              :class="{'is-invalid': guarantorInstitutionsRequired}">
                        <option :value="n.id" v-for="(n, p) in institutions" :key="`guarantor-${i}-${p}-institutions`">{{ n.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="text-danger p-2" v-if="guarantorInstitutionsRequired">
                    Guarantor institution details required
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <!--<a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
          <i class="mdi mdi-arrow-left"></i> Previous
        </a>-->
        <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
          Next <i class="mdi mdi-arrow-right"></i>
        </a>
      </div>
    </template>
  </loan-card>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters, mapState } = createNamespacedHelpers('loans');
import LoanCard from "./LoanCard";
import NavTabs from "./NavTabs";
import Constants from "../constants";
import VueChosen from "./VueChosen";
import $ from 'jquery';
import _ from 'lodash';
import Locations from "./Locations";
import Firebase from "../firebase";
import moment from 'moment';

export default {
  name: "BorrowerCharacteristics",
  components: {Locations, VueChosen, NavTabs, LoanCard},
  data() {
    return {
      time: {start: null, end: null},
      collateral_type: [],
      collateral_locations: [],
      collateral_value: {},
      business_profit: '',
      profit_frequency: '',
      tin: '1',
      gender: 'male',
      license: '1',
      account: '0',
      institution: [],
      statements: '0',
      statements_months: '',
      guarantors: '0',
      guarantor_number: '',
      guarantor_number_clients: '',
      client: '',
      guarantor_institutions: {},
      house_location: 'current_location',
      location_of_house: '',
      business_location: 'current_location',
      location_of_business: '',
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted() {
    this.time.start = moment().format();

    Firebase.sendEvent("Collateral requirements tab");
    if (this.requirements || this.personal_requirements)
      this.populate(this.requirements, this.personal_requirements, this.loan_requirements);

    _.each(this.collateral, (collateral) => {
      this.collateral_value[collateral.id] = {
        id: collateral.id,
        name: collateral.collateral_name,
        collateral_value: 0,
        collateral_location: 'current_location',
        collateral_village: '',
        location_required: collateral.location_required
      };
    })
  },
  validations () {
    return {
      business_profit: { required, numeric },
      profit_frequency: { required },
      house_location: {required},
      business_location: {required},
    }
  },
  computed: {
    ...mapState(['collateral', 'locations', 'institutions']),
    ...mapGetters({
      personal_requirements: Constants.GET_PERSONAL,
      requirements: Constants.GET_COLLATERAL,
      loan_requirements: Constants.GET_LOAN
    }),
    loan() {
      return {
        house_location: this.house_location,
        business_location: this.business_location,
        time: this.time,
        respondent_id: this.respondent_id,
        loan_size: this.loan_size,
        payment_frequency: this.payment_frequency,
        loan_term: this.loan_term,
        card: this.card,
        loan_type: this.loan_type,
        display_type: this.display_type,
        max_installment: this.max_installment,
      }
    },
    collateral_() {
      return {
        time: this.time,
        collateral_type: this.collateral_type,
        collateral_value: this.collateral_value,
        business_profit: this.business_profit,
        profit_frequency: this.profit_frequency
      }
    },
    hasCollateral() {
      return _.size(this.final_collateral_value) > 0;
    },
    final_collateral_value() {
      let values = _.values(this.collateral_value);

      return _.filter(values, (c) => {
        return _.includes(this.collateral_type, c.id)
      })
    },
    emptyCollateralValue() {
      if (this.hasCollateral) {
        return _.filter(this.final_collateral_value, (item) => {
          return item.collateral_value.toString() === '0'
        });
      }

      return [];
    },
    emptyCollateralVillage() {
      if (this.hasCollateral) {
        return _.filter(this.final_collateral_value, (item) => {
          return item.collateral_village.length === 0 && item.collateral_location === 'different_location'
        });
      }

      return [];
    },
    hasEmptyCollateralValue() {
      return _.size(this.emptyCollateralValue) > 0;
    },
    hasEmptyCollateralVillage() {
      return _.size(this.emptyCollateralVillage) > 0;
    },
    rand() {
      return _.random(_.range(50, 100000));
    },
    hasAccount() {
      return this.account === '1';
    },
    institutionRequired() {
      return this.hasAccount & this.institution.length === 0;
    },
    hasStatements() {
      return this.statements === '1';
    },
    statementsMonthsRequired() {
      if (this.hasAccount)
        return this.hasStatements && _.toNumber(_.trim(this.statements_months)) === 0;

      return false;
    },
    isClient() {
      return this.client === '1';
    },
    guarantorInstitutionsRequired() {
      let values = _.values(this.guarantor_institutions);

      let empty = _.filter(values, (v) => {
        return _.size(v.institutions) === 0;
      });

      return this.isClient && _.size(empty) !== 0;
    },
    hasGuarantors() {
      return this.guarantors === '1';
    },
    guarantorNumberRequired() {
      return this.hasGuarantors && _.toNumber(_.trim(this.guarantor_number)) === 0;
    },
    guarantorNumberClientRequired() {
      return this.hasGuarantors && _.toNumber(_.trim(this.guarantor_number_clients)) === 0;
    },
    invalid() {
      return this.institutionRequired || this.statementsMonthsRequired ||
          this.guarantorInstitutionsRequired || this.guarantorNumberRequired;
    },
    personal() {
      return {
        gender: this.gender,
        time: this.time,
        tin: this.tin,
        license: this.license,
        account: this.account,
        institution: this.institution,
        statements: this.statements,
        statements_months: this.statements_months,
        guarantors: this.guarantors,
        guarantor_number: this.guarantor_number,
        guarantor_number_clients: this.guarantor_number_clients,
        client: this.client,
        guarantor_institutions: this.guarantor_institutions,
      }
    },
    guarantor_range() {
      return _.range(this.guarantor_number_clients);
    }
  },
  methods: {
    ...mapActions([Constants.SET_POSITION, Constants.SET_COLLATERAL, Constants.SET_PERSONAL, Constants.SET_LOAN]),
    next() {
      _.each(this.collateral_value, (item) => {
        $(`#${item.id}-collateral-value`).removeClass('is-invalid');
        $(`#${item.id}-collateral-village`).removeClass('text-danger border-danger');
        $(`#${item.id}-collateral-village-label`).removeClass('text-danger');
        $(`#${item.id}-collateral-village-text`).addClass('d-none');
      });

      if (this.hasEmptyCollateralValue) {
        _.each(this.emptyCollateralValue, (item) => {
          $(`#${item.id}-collateral-value`).addClass('is-invalid');
        });

        return;
      }

      if (this.hasEmptyCollateralVillage) {
        _.each(this.emptyCollateralVillage, (item) => {
          $(`#${item.id}-collateral-village`).addClass('text-danger border-danger');
          $(`#${item.id}-collateral-village-label`).addClass('text-danger');
          $(`#${item.id}-collateral-village-text`).removeClass('d-none');
        });

        return;
      }

      this.v$.$touch();
      if (this.v$.$error) return;

      this.time.end = moment().format();

      Firebase.sendEvent("Collateral requirements submitted");

      this.SET_COLLATERAL(this.collateral_);
      this.SET_PERSONAL(this.personal);
      this.SET_LOAN({requirements: this.loan, position: false});
      this.SET_POSITION(2);
    },
    /*previous () {
      Firebase.sendEvent("Collateral requirements previous button clicked");
      this.SET_POSITION(1);
    },*/
    populate(requirements, personal_requirements, loan_requirements) {
      this.collateral_type = requirements.collateral_type;
      this.collateral_value = requirements.collateral_value;
      this.business_profit = requirements.business_profit;
      this.profit_frequency = requirements.profit_frequency;
      this.tin = personal_requirements.tin;
      this.gender = personal_requirements.gender;
      this.license = personal_requirements.license;
      this.account = personal_requirements.account;
      this.institution = personal_requirements.institution;
      this.statements = personal_requirements.statements;
      this.statements_months = personal_requirements.statements_months;
      this.guarantors = personal_requirements.guarantors;
      this.guarantor_number = personal_requirements.guarantor_number;
      this.guarantor_number_clients = personal_requirements.guarantor_number_clients;
      this.client = personal_requirements.client;
      this.guarantor_institutions = personal_requirements.guarantor_institutions;
      this.respondent_id = loan_requirements.respondent_id;
      this.loan_size = loan_requirements.loan_size;
      this.payment_frequency = loan_requirements.payment_frequency;
      this.loan_term = loan_requirements.loan_term;
      this.card = loan_requirements.card;
      this.loan_type = loan_requirements.loan_type;
      this.max_installment = loan_requirements.max_installment;
    },
    setVillage(payload) {
      this.collateral_value[payload.position]['collateral_village'] = payload.village;
    },
    setVillageLocation(payload) {
      if (payload.position === 'house')
        this.house_location = payload.village;

      if (payload.position === 'business')
        this.business_location = payload.village;
    }
  },
  watch: {
    guarantor_number_clients() {
      if (this.guarantor_number_clients > 0) {
        this.guarantor_institutions = {};
        _.each(this.guarantor_range, (r) => {
          this.guarantor_institutions[r + 1] = {institutions: []};
        })
      }
    },
    collateral_type() {
      if(_.size(this.collateral_type) > 0 && _.size(this.collateral_value) === 0) {
        _.each(this.collateral, (collateral) => {
          this.collateral_value[collateral.id] = {
            id: collateral.id,
            name: collateral.collateral_name,
            collateral_value: 0,
            collateral_location: 'current_location',
            collateral_village: '',
            location_required: collateral.location_required
          };
        })
      }
    },
    location_of_house() {
      if (this.location_of_house === 'current_location') {
        this.house_location = this.location_of_house;
      } else {
        this.house_location = '';
      }
    },
    location_of_business() {
      if (_.includes(['current_location', 'house_location'], this.location_of_business)) {
        this.business_location = this.location_of_business;
      } else {
        this.business_location = '';
      }
    }
  }
}
</script>

<style scoped></style>